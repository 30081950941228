* {
  color: rgb(0, 0, 0);
  font-family: 'PT Sans', sans-serif;
}
body {
  background-color: white;
}
/* Navbar */
.nav-img {
    width: 50%;
}
.nvbr a {
  font-family: 'Oswald', sans-serif!important;
  font-size: 20px;
}
.nvbrChild {
  color: black;
  margin-left: 15px;
}
.nav-link {
  font-family: 'Oswald', sans-serif!important;
  font-size: 20px;
}
.COG {
  font-family: 'Oswald', sans-serif!important;
  font-size: 20px;
}

/* colors */
.black {
  background-color: black!important;
}
.white {
  background-color: white!important;
}
.almostWhite {
  background-color: rgb(234, 234, 234)!important;
}

/* Page 1 */
.first-page {
    color: white;
}
.fpr1 {
  padding: 150px 0px;
}
.fpr1Content {
  padding: 20px 0px;
  /* background-color: black; */
}
.fpr2 {
  margin-top: 70px;
}
.fpr3 {
  margin-top: 70px;
}
.fpr4 {
  margin-top: 100px;
  text-align: center;
}
.fpcard {
  margin: 20px 0px;
  text-align: center;
}
.fpimgMain {
  margin: 30px 0px;
}
/* .mainText {
  text-align: ;
} */
.heading {
  font-size: 20px;
  background-color: #dedede;
  color: #000000;
  padding: 7px;
  width: 200px;
  text-align: center;
} 
.fphimg {
  width: 100%;
  border-radius: 20px;
}
.containImg {
  position: relative;
  text-align: center;
  color: white;
}
.positioning1 {
  position:absolute;
  margin-top: -15px;
  margin-left: 20px;
  z-index: 2;
  max-width: 300px;
}
.ytLink {
  text-decoration: none;
  color: white;
}
.ytLink2 {
  margin: 80px 0px;
}
.ytLink3 {
  font-size: 50px;
  text-decoration: none;
  border: 2px solid #b4b4b4;
  color: #000;
  border-radius: 20px;
  padding: 15px 40px;
}
.ytLink3:hover {
  background-color: rgb(181, 181, 181);
  color: #313131;
}
.ytLinkText {
  margin-top: 30px;
}
.notUnderlined {
  text-decoration: none;
}
/* .fprow {
  background-color: #2EB0EA;
  background-color: black;
} */
.mainText {
  max-width: 300px;
  margin: auto;
}
.mainLink:hover h4 {
  color: #b4b4b4;
}
.homeMainLink {
  text-align: center;
}
.underlined {
  text-decoration: underline;
  text-decoration-color: black;
}
.underlined:hover {
  text-decoration-color: #b4b4b4;
}
.homeHeader {
  position: relative; top: -40px;
  text-align: center;
  font-size: 25px;
  color: white;
}
.facebookImg {
  width: 25px;
  margin-left: 5px;
}
.facebookText {
  font-size: 20px;
  text-align: center;
}
.homepageHeader {
  aspect-ratio: 21/9;
}

.tilt-neon {
  font-family: "Tilt Neon", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "XROT" 0,
    "YROT" 0;
}

/* ministries */
.crd {
  background-color: #2EB0EA!important;
  border-radius: 20px;
}
.slideImg {
  height: 650px;
  opacity: 80%;
}
.slideLabel {
  font-size: 55px;
  position: relative; top: -250px;
}
.slideText {
  position: relative; top: -100px
}
.miniPara {
  background-color: #2EB0EA;
  padding: 20px;
  /* border-radius: 20px; */
}
.clock2 {
  margin-top: 10px;
}
.miniHeader {
  padding: 200px 0px;
  font-size: 25px;
  position: absolute;
  max-width: 1400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.verse {
  max-height: 1000px;
}
.miniHTxt {
  color: white;
  margin: 0px 50px;
  max-width: 700px;
}
.miniRow3{
  background-color: white;
}
.r3Text {
  color: black;
  font-size: 20px;
  padding: 20px;
}
.cara {
  max-width: 1400px;
  z-index: 2;
}
.verse {
  position: relative;
  text-align: center;
}

/* Schedule */
.eItem {
  border: 2px rgb(99, 99, 99) solid;
  border-top: none;
  border-radius: 20px!important;
  margin: 10px;
}
.vertical-menu {
  margin-top: 50px;
}
.eventDes {
  text-align: left;
}
.eventAdd {
  text-align: left;
}
.helloPng {
  opacity: .4;
  width: 100%;
  height: 700px;
  max-width: 1400px;
}
.sr1 {
  background-color: #292929;
  position: relative;
  text-align: center;
  color: white;
  max-width: 1400px;
}
.helloTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.stxt {
  color: white;
  text-align: left;
}
.sheader {
  color: white;
  font-size: 30px;
}

/* Photo fading */
.fadein {
  position: relative;
  background-color: #000;
  margin: 5px 0px;
  border-radius: 20px;

}
.fadein img {
  width: 100%;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
}
.fadein:hover img {
  opacity: 0.5;
}
.fadein .text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: rgba(255,255,255,0.5);
  color: #000;
  padding: 10px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease;
}
.fadein:hover .text {
  opacity: 1;
}

.liveStatus {
  color: red;
  display: inline;
}

.liveVideo {
  aspect-ratio: 16/9;
}

.clipThumbnail {
  aspect-ratio: 16/9;
}
.thumbnailLink {
  text-decoration: none;
  margin: 15px 0px;
}
.clipCard:hover {
  padding: 10px 0px!important;
  margin: -10px 0px;
}

.newFlame {
  color: red;
  margin-top: 30px;
  font-size: 100px;
  font-family: 'Bebas Neue', sans-serif;
}
.studentM {
  color: rgb(239, 123, 40);
  font-family: 'Bebas Neue', sans-serif;
  font-size: 35px;
  position: relative; top: -45px
}
.nfInstaLink {
  color: white;
  font-size: 20px;
}
.newFlameImg2 {
  width: 25px;
}
.nfInstaLink:hover {
  color: red;
}
.newFlameInsta {
  position: relative; top: -35px;
  color: white;
  font-size: 20px;
}
.newFlameImg {
  aspect-ratio: 16/9!important;
}
.newFlameWhoFor {
  position: relative; top: -32px;
  color: rgb(242, 122, 10);
}
.firstCara {
  margin-top: 55px;
  transform: rotate(-20deg);
  width: 32%;
  margin-right: 1.5%;
}
.secondCara {
  width: 32%;
}
.thirdCara {
  margin-top: 55px;
  transform: rotate(20deg);
  width: 32%;
  margin-left: 1.5%;
}
.newFlamePage {
  background-color: black;
}
.newFlameVerse {
  margin-top: 80px;
  color: white;
  font-size: 25px;
  margin-bottom: 20px;
}
.newFlameVerse2 center {
  font-family: 'Bebas Neue', sans-serif;
  color: white;
  font-size: 35px;
}
.youthSchedule {
  margin-top: 85px;
}
.youthScheduleHeader {
  font-family: 'Bebas Neue', sans-serif;
  color: white;
  font-size: 35px;
}
.youthScheduleContent {
  color: white;
  font-size: 25px;
}
.message {
  text-align: center;
  color: rgb(255, 233, 233);
  background-color: rgb(180, 0, 0);
  border: 3px solid black;
  padding: 5px;
  border-radius: 8px;
}

/*
Connection card page
*/
.connectionHeader {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-size: 35px;
  margin: 25px 0px 25px 0px
}
.notes {
  height: 100px;
}
.connection {
  margin: 5px;
  padding: 5px;
  background-color: #ebebeb;
  border-radius: 7px;
  font-size: 15px;
}
.connectionEntry {
  margin: auto;
}
.connectionTitleRow {
  margin: 10px;
  font-weight: 700;
}

/*
Young Adults Page
*/
.miniHeaderYA {
  font-size: 25px;
  position: relative; top: -150px;
}
.YAheading {
  font-size: 50px;
  font-family: 'Honk', system-ui;
  position: relative; top: -250px;
  z-index: 1;
}
.caraYA {
  max-width: 1400px;
  margin-top: 75px;
}
.YASchedule {
  margin-top: 85px;
}
.YAScheduleHeader {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 35px;
}
.YAScheduleContent {
  font-size: 25px;
}
.YAInstaLink {
  font-family: 'Honk', system-ui;
  color: white;
  font-size: 25px!important;
  position: relative; top: -65px;
}
.YAInstaLink:hover {
  color: orange;
}
.YAages {
  font-family: 'Honk', system-ui;
  color: white;
  font-size: 25px!important;
  position: relative; top: -20px;
}
.YAdesc {
  font-size: 20px;
}

/*
Footer
*/
.footer {
  background-color: rgb(234, 234, 234)!important;
}
.adminLoginLink {
  color:rgb(234, 234, 234)!important;
}
.adminLoginLink:hover {
  color:rgb(154, 154, 154)!important;
}
.locationIframe {
  aspect-ratio: 5/6;
  border: 3px solid black;
  border-radius: 7px;
  margin-bottom: 35px;
}
.footerLink {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 20px;
  color: black;
  display: block;
  margin-bottom: 5px;
  margin-left: 3px;
}
.footerHeader {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 35px;
  background-color: #b4b4b4;
  padding: 0px 15px;
  border-radius: 7px;
}
.footerHeader2 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 35px;
  background-color: #b4b4b4;
  padding: 0px 15px;
  border-radius: 7px;
  margin-top: 22px;
}

/*
Classes Page
*/

.classHeader {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 28px;
  color: black;
}
.classContent {
  margin: auto;
  font-size: 16px;
}
.afp {
  font-size: 16px;
  text-decoration: none;
}
.sslogo { 
  display: inline;
  margin-left: 5px;
}

/*
 Food 
*/
.feedMoreScheduleContent {
  font-size: 25px;

}
.feedMoreScheduleHeader {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 35px;
}
.feedMoreSchedule {
  margin-top: 50px;
}

/*
  Kids page
*/

.kidsVerse {
  text-align: center;
  font-size: 25px;
  margin-top: 55px;
}

/*
About page
*/
.aboutPeopleHeading {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 25px;
  background-color: #ebebeb;
  margin: 15px 0px 15px 0px;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
}
.aboutProfile {
  background-color: #ebebeb;
  padding: 15px;
  margin: 15px 0px 15px 0px;
}
.aboutPhoto {
  padding: 15px;
  border-radius: 60px;
  aspect-ratio: 3/2;
}
.aboutName {
  text-align: center;
  font-size: 25px;
}
.aboutTitle {
  text-align: center;
  font-size: 20px;
}
.aboutDesc {
  background-color: white;
  padding: 15px;
  border-radius: 20px;
}

/* .classHeading {
  font-family: 'Red Hat Display', sans-serif;
  text-align: center;
  font-size: 18px;
}
.classHeadingTwo {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 35px;
}
.classList {
  margin-top: 35px;
}
.classDropdown {
  margin-top: 25px;
}
.classListItem:hover {
  background-color: #b4b4b4;
  cursor: pointer;
}
.listItemSelected {
  background-color: #b4b4b4;
}
.classContent {
  margin-top: 35px;
}
.nfsmLink {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 25px;
  color: black;
} */

/*
Events sign up
*/
.event {
  margin: 15px;
  background-color: #dedede;
  border: 2px solid black;
  border-radius: 15px;
  padding: 15px;
}
.eventTitle {
  font-size: 30px;
  font-family: 'Bebas Neue', sans-serif;
  margin-bottom: -15px;
  
}
.eventTime {
  font-size: 20px;
}
.eventDescription {
  margin-top: 15px;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
}
.eventLocation {
  margin-top: 15px;
}
.signUpButton {
  color: black;
  background-color: white;
  border: 1px solid black;
}
.signUpButton:hover {
  background-color: #b4b4b4;
  border: 1px solid black;
}
.personEntry {
  margin: 10px 0px;
}
.eventsSUHeader {
  text-align: center;
  margin: 30px 0px 16px 0px;
  font-size: 30px;
  font-family: 'Bebas Neue', sans-serif;
}
.noEvents {
  text-align: center;
  margin: 200px 0px;
  font-size: 30px;
  font-family: 'Bebas Neue', sans-serif;
}


/*
Admin Page
*/
.adminCard {
  margin-top: 15px;
}
.adminTxtArea {
  margin-top: 25px;
}
.redirectProfile {
  text-decoration: none;
}
.secondText {
  text-align: left;
}

/*
Global
*/
.forceCaraAspect {
  aspect-ratio: 18/9;
}

@media (min-width:360px) {
  .YAheading {
    font-size: 60px;
    position: relative; top: -280px;
  }

}

@media (min-width:500px) {
  .YAheading {
    font-size: 60px;
    position: relative; top: -360px;
  }

}

@media (min-width:576px) {
  .YAheading {
    font-size: 80px;
    position: relative; top: -400px;
  }
  .YAInstaLink {
    position: relative; top: -85px;
  }

}

@media (max-width:768px) {
  .locationIframe {
    aspect-ratio: 3/2!important;
    margin-top: 15px;
  }

}

@media (min-width:768px) {
  /* .fpimgMain {
    margin: 0px 0px;
  } */
  .fpcard {
    margin: 70px 0px 30px 0px;
  }
  .mainLink {
    max-width: none;
  }
  .slideImg {
    height: 1000px;
  }
  .heading {
    padding: 20px;
    font-size: 30px;
    width: 350px;
  }
  .stxt {
    font-size: 25px;
  }
  .sheader {
    font-size: 35px;
  }
  .helloPng {
    height: 1100px;
  }
  .YAheading {
    font-size: 80px;
    position: relative; top: -480px;
  }
  .YAInstaLink {
    font-size: 35px!important;
  }
  .YAages {
    font-size: 35px!important;
  }
  .classHeading {
    font-size: 25px;
  }
  .classContent {
    margin: auto;
    font-size: 18px;
  }

}
@media (min-width: 992px) {
    .nav-img {
        width: 35%;
    }
    .slideImg {
      height: 1000px;
    }
    .navbar-collapse {
        display: flex;
        flex-direction: row-reverse;
    }
    .heading {
      font-size: 40px;
      width: 550px;
    }
    .miniHeader {
      font-size: 50px;
    }
    .YAheading {
      font-size: 100px;
      position: relative; top: -630px;
    }
    .YAInstaLink {
      position: relative; top: -100px;
    }
    .YAages {
      position: relative; top: -25px;
    }
    .locationIframe {
      aspect-ratio: 10/9;
    }
    .feedMoreSchedule {
      margin-top: 100px;
    }
}
@media (min-width: 1200px) {
  .YAheading {
    font-size: 140px;
    position: relative; top: -760px;
  }
    .slideImg {
      height: 1200px;
    }
    .helloPng {
      height: 1200px;
    }
    .locationIframe {
      aspect-ratio: 8/5;
    }
    .feedMoreSchedule {
      margin-top: 130px;
    }
    
}
@media (min-width: 1400px) {
  .YAheading {
    font-size: 180px;
    position: relative; top: -880px;
  }
  .YAInstaLink {
    position: relative; top: -220px;
  }
  .YAages {
    position: relative; top: -60px;
  }
  .feedMoreSchedule {
    margin-top: 150px;
  }
}